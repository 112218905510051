import Layout from '../components/layout'
import LoginForm from "../components/pages/login/loginForm";
import {useEffect} from "react";
import {
    getPostJs,
    getPreJs,
    hasRedtrackData,
    postView,
    preClick,
    preInit,
    preView,
    redHasClickId, redtrackSubmitInit
} from "../utils/redtrack";

function Login() {

    useEffect(() => {
        redtrackSubmitInit()
    }, []);

    const loginView =
        <div>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="cont-detail">
                            <div className="">
                                <h2>Existing User Login</h2>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <div className="forgotpass">
                            <LoginForm/>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    return (
        <Layout preHeader={loginView} containerClassName="main-contact-container" >

        </Layout>
    )
}

export default Login;
